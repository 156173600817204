(() => {
	let timeout: number | null = null;

	function toggleOffLine() {
		console.warn('running offline');
		document
			.querySelectorAll('.hide-when-offline')
			.forEach((element: Element) => {
				element.classList.add('hide-when-offline--hide');
				element.setAttribute('aria-hidden', 'true');
			});
	}

	function toggleOnline() {
		document
			.querySelectorAll('.hide-when-offline')
			.forEach((element: Element) => {
				element.classList.remove('hide-when-offline--hide')
				element.setAttribute('aria-hidden', 'false');
			});
	}

	function checkOnline() {
		if (navigator.onLine) {
			if (timeout) {
				window.clearInterval(timeout);
				timeout = null;
			}
			toggleOnline();
		}
	}

	if (!navigator.onLine) {
		toggleOffLine();
		timeout = window.setInterval(checkOnline, 10000);
	}
})();
